

























































































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Inject, Ref } from 'vue-property-decorator'
import { PoolDetailViewModel } from '../viewmodels/pool-detail-viewmodel'

@Observer
@Component({
  components: {}
})
export default class EndedCard extends Vue {
  @Inject() vm!: PoolDetailViewModel
  @Ref('form') form: any

  closeDialog() {
    this.form.reset()
    this.vm.closeBuyBoxDialog()
  }
}
